/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    ${props => props.type === "outline" && css`
      background: transparent;
      border: 2px solid #00a7e0;
      color: #00a7e0;
    `}

    ${props => props.type === "primary" && css`
      background: #00a7e0;
    `}
    padding: 15px;
    padding-left: 35px;
    padding-right: 50px;
    color: #fff;
    font-weight: 700;
    transition: 150ms;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 18px;
    border-radius: 50px;

    &:hover {
      color: #fff;
      transform: scale(1.03);
    }

    &::after {
      content: "";
      width: 11px;
      height: 19.5px;
      background: url("../../img/arrow-right.svg") no-repeat;
      background-size: contain;
      background-position: center;
      display: block;
      margin-left: 15px;
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .custom-img {
    position: absolute;
    right: 15px;
    height: 18px;
    width: 18px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`

const ButtonDefault = ({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  isRound = 0,
  width = 0,
  type = 'primary',
}) => (
  <StyledButton
    className={className}
    width={width}
    type={type}
    isRound={isRound}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
