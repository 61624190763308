/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import 'styles/all.scss'
import 'styles/HomeTemplate.scss'

// Components
import ParseContent from 'components/shared/ParseContent'
import SEO from 'components/shared/SEO'

// Third Party
import { motion } from 'framer-motion'
import styled from 'styled-components'

const BrandTitle = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;

  h2 {
    width: 220px;
    background-color: #FFFFFF;
    padding: 5px 15px 10px 15px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    @media screen and (max-width: 768px) {
      width: 180px;
    }

    @media screen and (max-width: 576px) {
      width: 135px;
    }
  }
`

const Home = ({ data }) => {
  const { techniek, yacht } = data

  return (
    <div>
      <SEO title={"Veritec Group"} />
      <div className="home-page">
        <div className="container">
          <div className="home-page-image-container mx-0 row position-absolute">
            <Img loading="eager" className="col-md-6 px-0" fluid={yacht.childImageSharp.fluid} alt="" />
            <Img loading="eager" className="col-md-6 px-0" fluid={techniek.childImageSharp.fluid} alt="" />
          </div>
          <div className="home-page-row row">
            <BrandTitle>
              <h2 className="with-dot">veritec</h2>
            </BrandTitle>
            <div className="home-page-employers d-flex justify-content-center align-items-center col-md-6">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: .3, type: 'spring', damping: 20 }}
                className="home-page-content pr-lg-5 pr-md-4"
              >
                <ParseContent content='
                  <h2 style="text-align: right;">
                    <strong>VERITEC</strong>
                    <br />
                    YACHTS
                  </h2>
                  <div style="text-align: right;">
                    <a class="custom-button" href="https://www.veritecyachts.nl">
                      Naar de website
                    </a>
                  </div>
                  '
                />
              </motion.div>
            </div>
            <div className="home-page-candidates d-flex justify-content-center align-items-center col-md-6">
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: .15, duration: .3, type: 'spring', damping: 20 }}
                className="home-page-content pl-lg-5 pl-md-4"
              >
                <ParseContent content='
                  <h1 style="text-align: left;">
                    <strong>VERITEC</strong>
                    <br />
                    TECHNIEK
                  </h1>
                  <div style="text-align: left;">
                    <a class="custom-button-alt" href="https://www.veritectechniek.nl">
                      Naar de website
                    </a>
                  </div>
                  '  
                />
              </motion.div>      
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;

export const query = graphql`{  
  techniek: file(relativePath: {eq: "veritec-techniek-lp.jpg"}) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }

  yacht: file(relativePath: {eq: "veritec-yachts-lp.jpg"}) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
}`